

function App() {

  const mailTo = () => {
    window.location = 'mailto:rolfkorp@gmail.com';
  }
  return (
    <div className="container h-screen w-screen bg-white font-extrabold flex items-center justify-center ">
      <h1 className="text-black lg:text-9xl text-6xl hover:cursor-pointer transform hover:scale-150 transition duration-700"
          onClick={mailTo}>RolfKorp</h1>
    </div>
  );
}

export default App;
